.cmp-container-bloque-desc-producto-imagen {

       .elemento-flex {
        

        .cmp-text{

            h3{
                font-family: $font-family-title-bold;
                font-size: $h2-size;
                font-weight: 500;
                color: #ffffff;
                text-align: center;
                line-height: normal;
            }
        }
    }
}

.cmp-container-fondo-esmerilado-blanco {
        
        .cmp-container{
            border-radius: 10px;
        }
}


@media (max-width: $breakpoint-mob-tab) {


.cmp-container-hero-azul-alfa {

    .cmp-container {
        background-image: none !important;

    }

}

.cmp-container-barra-blanca-top {
    
    

    h3 {
        font-size: 3.5rem;
        margin: 25px;
    }
}
}


@media (min-width: $breakpoint-mob-tab) {

    .cmp-container-bloque-desc-producto-imagen {

        .aem-Grid {
            display: flex;
        }
        .elemento-flex {
            display: flex !important;

            .cmp-container{
                flex: 1;
                display: flex;
                align-items: center;
            }

            .cmp-text{
                margin-top: 20%;

                h3{
                    font-family: $font-family-title-bold;
                    font-size: 100px;
                    font-weight: 500;
                    color: #ffffff;
                    text-align: center;
                    line-height: 86px;
                }
            }
        }

        .cmp-container-barra-blanca-top {
            position: relative;
            
            

            .cmp-image__image {
                position: relative;
            }
        }

        .cmp-container-barra-blanca-top::before {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 20%;
            background-color: #ffffff; /* Color de fondo deseado */
        }
    }

    .cmp-container-hero-azul-alfa {
        position: relative;

        .cmp-container {
            background-size: cover !important;
            background-color: transparent !important;
            background-position-x: left;

        }

       /*  & > .cmp-container::before {
            content: "";
            display: block;
            position: absolute;
            background-color: rgba(0, 69, 147, 0.35);
            width: 100%;
            height: 96.5%;
        } */
    }
}



