.standard-footer-lib {
  .cmp-separator {
    width: 80%;
  height: 1px;
  margin: 8px 266px 20px 6px;
  background-color:#7dc0ff;
  }
  .cmp-separator__horizontal-rule {
    
  }
}
