/// BLANCO

.cmp-accordion-blanco-libertad {

    .cmp-title__text {
        color: $color-title-blue-acordeon;

    }

    .cmp-accordion__title {
        color: $color-title-blue-acordeon;
        font-size: $h5-size;
    }

    .cmp-accordion__button {
        border-bottom-color: $color-blue-light;
    }

    .cmp-accordion__button .cmp-accordion__icon:after {
        content: "+";
        background-color: transparent;
        -webkit-mask: none;
        mask: none;
    }

    .cmp-accordion__button--expanded .cmp-accordion__icon:after {
        content: "-";
        background-color: transparent;
        -webkit-mask: none;
        mask: none;
    }

    .cmp-accordion__button:focus {
        border: 0.125em solid transparent;
        border-radius: 0.25em;
        border-bottom-color: #7dc0ff;
    }

    .cmp-accordion__button:focus .cmp-accordion__title,
    .cmp-accordion__icon {
        color: #002c72;
    }
}


/// AZUL


.cmp-accordion-azul-libertad {

    .cmp-accordion {
        margin: 0;
    }

    .cmp-title__text {
        color: $color-title-blue-acordeon;

    }

    .cmp-accordion__title {
        color: $color-title-blue-acordeon;
        font-size: $h5-size;
        line-height: normal;
    }

    .cmp-accordion__item:first-child {
        .cmp-accordion__button {
            border-bottom-color: transparent;
            background-color: $color-bg-blue-acordeon;
        }

        .cmp-accordion__title {
            color: #ffffff;
        }

        .cmp-accordion__button .cmp-accordion__icon:after {
            content: "+";
            background-color: transparent;
            -webkit-mask: none;
            mask: none;
            color: #ffffff;
        }

        .cmp-accordion__button--expanded .cmp-accordion__icon:after {
            content: "-";
            background-color: transparent;
            -webkit-mask: none;
            mask: none;
            color: #ffffff
        }
    }

    .cmp-accordion__button .cmp-accordion__icon:after {
        content: "+";
        background-color: transparent;
        -webkit-mask: none;
        mask: none;
    }

    .cmp-accordion__button--expanded .cmp-accordion__icon:after {
        content: "-";
        background-color: transparent;
        -webkit-mask: none;
        mask: none;
    }

    .cmp-accordion__button {
        border-radius: 0 0 8px 8px;
        padding: 26px 30px;
        border-bottom-color: transparent;
        background-color: $color-bg-gray-acordeon;
    }



    .cmp-accordion__button:focus {
        border: transparent;
        border: 0.125em solid #0000;
    }
}



/// AZUL OSCURO


.cmp-accordion-azul-oscuro-libertad {

    .cmp-accordion {
        margin: 0;
    }

    .cmp-title__text {
        color: $color-title-blue-acordeon;

    }

    .cmp-accordion__title {
        color: $color-title-blue-acordeon;
        font-size: $h5-size;
        line-height: normal;
    }

    .cmp-accordion__item:first-child {
        .cmp-accordion__button {
            border-bottom-color: transparent;
            background-color: $color-title-blue-acordeon;
        }

        .cmp-accordion__title {
            color: #ffffff;
        }

        .cmp-accordion__button .cmp-accordion__icon:after {
            content: "+";
            background-color: transparent;
            -webkit-mask: none;
            mask: none;
            color: #ffffff;
        }

        .cmp-accordion__button--expanded .cmp-accordion__icon:after {
            content: "-";
            background-color: transparent;
            -webkit-mask: none;
            mask: none;
            color: #ffffff
        }
    }

    .cmp-accordion__button .cmp-accordion__icon:after {
        content: "+";
        background-color: transparent;
        -webkit-mask: none;
        mask: none;
    }

    .cmp-accordion__button--expanded .cmp-accordion__icon:after {
        content: "-";
        background-color: transparent;
        -webkit-mask: none;
        mask: none;
    }

    .cmp-accordion__button {
        border-radius: 0 0 8px 8px;
        padding: 26px 30px;
    }

    .cmp-accordion__button {
        border-bottom-color: transparent;
        background-color: $color-bg-gray-acordeon;
    }

    .cmp-accordion__button:focus {
        border: none !important;
    }


//estilos nuevos para el acordeon del footer libertad
    .border-acordeon-footer-lib {
        .cmp-accordion__button:focus .cmp-accordion__button--expanded:focus {
            border: none !important;
        }
    }



}

.no-background-libertad-acordeon {
    .cmp-accordion {
        .cmp-accordion__item {
            .cmp-accordion__header {
                .cmp-accordion__button { //Estilos de acordeon sin background
                    background: none !important;
                    padding: 20px;
                    border-bottom: none;


                
                }

            }

        }

    }


    .cmp-accordion__title {
        color: white !important;
    }

    .cmp-accordion__button {

        &.cmp-accordion__button--expanded {
            &:focus { //Remueve borde azul acordeon expandido & texto blanco

                border:none;

                .cmp-accordion__title {
                    color: white !important;
                }
            }

            
        }

        &:hover{
            background-color: #7dc0ff;
            color: #7dc0ff;

        }

        &:focus { //Remueve borde azul acordeon & texto blanco
            color: white !important;
            border:none;

        }

        .cmp-accordion__icon {
            &:after{
                background-color: #7dc0ff;
            }
            position: absolute;
        }
    }

}




/* #accordion-31e9f18044-item-ce7c9b8275-button {}*/