.container-footer{

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100vw;
    height: max-content;
    padding: 25px;
    margin: 0;

    .contenedor-flex {
      .contenedor-flex{
        .aem-Grid{
           margin: 0 20px;
        }
      }
    }

  }
  
  .icons-container{
    width: inherit;
    display: flex;
    justify-content: flex-end;
    aling-items: center;
    margin-right: 500px
  }
  
  .container-social-media{
    width: 80px;
    height: 80px;
      border: 1px solid red;
        margin-right: 25px 
  }
  
  .content-container{
   // border: 1px solid red;
    width: inherit;
      display: flex;
    justify-content: space-evenly;
   
  }
  
  .content{
    width: 400px;
    height: 450px;
    //border: 1px solid red
  }
  
  .content-title{
  width: 200px;
  padding: 5px;
  text-decoration: underline blue;
  color: white
  }
  
  
  .link-container{
    display: flex;
    justify-content: space-evenly;
    width: inherit
  /*   margin-right: 600px */
  }
  
  a{
    //border: 1px solid red
  }
  
  .copy-container{
    background-color: black;
      width: inherit;
        color: white;
          text-align: center
  }
  
 