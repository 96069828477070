.h1_style {
  .cmp-title {
  }
  .cmp-title__text {
    font-weight: lighter;
    font-size: $h1-size;
    font-family: $font-family-title;
    line-height: 4.125rem;
  }
  .cmp-title__link {
  }
}

.h2_style {
  .cmp-title {
  }
  .cmp-title__text {
    font-weight: normal;
    font-size: $h2-size;
    font-family: $font-family-title;
    line-height: 3.0625rem;
  }
  .cmp-title__link {
  }
}

.h3_style {
  .cmp-title {
  }
  .cmp-title__text {
    font-weight: normal;
    font-size: $h3-size;
    line-height: 2.25rem;
  }
  .cmp-title__link {
  }
}

.h4_style {
  .cmp-title {
  }
  .cmp-title__text {
    font-weight: normal;
    font-size: $h4-size;
    line-height: normal;
  }
  .cmp-title__link {
    color: #7dc0ff !important;
    text-decoration: underline;
  }
}

.h5_style {
  .cmp-title {
  }
  .cmp-title__text {
    font-weight: normal;
    font-family: Libertad Medium;
    font-size: $h5-size;
  }
  .cmp-title__link {
    color: #7dc0ff;
    text-decoration: underline;
    text-decoration-color: #7dc0ff;
  }
}

.h6_style {
  .cmp-title {
  }
  .cmp-title__text {
    color: $color-shade-3;
    font-weight: normal;
    font-size: $h6-size;
    text-transform: uppercase;
  }
  .cmp-title__link {
  }
}

a.cmp-title__link:focus {
  border-color: transparent !important;
}
