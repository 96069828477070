.cmp-image {
  margin-top: 2.25em;
}
.cmp-image__link {
}
.cmp-image__image {
  display: block;
}
.cmp-image__title {
}


//zoom menu flotante
.zoom-lib-img {
  .cmp-image__link {
      img {
          width: 100%; // La imagen ocupa todo el ancho del contenedor
          transition: transform 0.5s ease; // Transición suave para el efecto de zoom
      }

      img:hover {
          transform: scale(1.6); // Aumenta el tamaño de la imagen en un 20%
      }


        // Para pantallas de tabletas y móviles
    @media (max-width: 768px) {
      .cmp-image__link img:hover {
          transform: scale(1.1); // Zoom del 10% en pantallas más pequeñas
      }
  }

  // Para pantallas móviles muy pequeñas
  @media (max-width: 480px) {
      .cmp-image__link img:hover {
          transform: scale(1.05); // Zoom del 5% en pantallas muy pequeñas
      }
  }


      
  }

  
}




//imagen del menu flotante en mobile
.menu-flotante-lib-img {
  .cmp-image {
      img {
          height: 120px;
      
      }

     


 



      
  }

  
}

