  .img-footer {
    .cmp-image {
 
    }
    .cmp-image__link {}
    .cmp-image__image {
  
    }
    .cmp-image__title {
      display: none;
    }
  }

@media (min-width: $breakpoint-mob-tab) {
  
  .img-icon-azul-simulador{
    .cmp-image{
      display: flex;
      justify-content: center;

      .cmp-image__image {
        width: 89px;
      }
    }
  }
}