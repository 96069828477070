.cmp-button-cta-borde-blanco {

    position: relative;

    .cmp-button {
        border-radius: 7px;
        display: block;
        margin: 0;
        border: solid 3px #fff;
        text-decoration: none;
        
        
    }
    border-style: none;
    a {       
        color: #ffffff;       
    }

    .cmp-button__text {
        font-family: $font-family-title-bold;
    }

}

.cmp-button-cta-fondo-amarillo {

    position: relative;

    .cmp-button {
        border-radius: 7px;
        background-color: $color-amarillo-libertad;
        display: block;
        margin: 0;
        border: none;
        text-decoration: none;
        border: solid 3px $color-amarillo-libertad;
        
        
    }
    border-style: none;
    a {       
        color: #ffffff;       
    }

    .cmp-button__text {
        font-family: $font-family-title-bold;
    }

}

.cmp-button-cta-fondo-azul-gradado {

    justify-content: center;
    display: flex;
    position: relative;

    .cmp-button {
        border-radius: 7px;
        background-image: linear-gradient(to bottom, #007bc5, #004593);
        display: block;
        padding: 14px 60px;
        margin: 0;
        border: none;
        text-decoration: none;
        text-align: center;
        
        
    }
    border-style: none;
    a {       
        color: #ffffff;       
    }

    .cmp-button__text {
        font-family: $font-family-title-bold;
    }

}

.cmp-button-centrar {
    display: flex !important;
    justify-content: center;

    .cmp-button {
        min-width: 65%;
        text-align: center;
    }
}