.cmp-container-social-botones{


    .cmp-button {
        border-radius: 0.5em;
        display: block;
        margin: 0;
        border-style: none;
        text-decoration: none;
        
        
    }
    border-style: none;
    a {       
        color: #ffffff;       
    }

    .cmp-button__text {
        
    }

    .cmp-button-twitter {
        background-color: $color-twitter-blue;
        display: flex !important;
        justify-content: center !important;
        border-radius: 5px;
        min-width: 304px;
        margin: 0 4px;
        font-family: Helvetica;
        font-size: $h6-size;

        .cmp-button::after {
            content: url(./resources/images/twitter.svg);
            filter: brightness(200%);
            display: inline-block;
            width: 15px; 
            height: 15px; 
            margin-left: -90px;
            position: absolute;
            transform: translateY(10%);
            
        }
        
    }

    .cmp-button-facebook {
        background-color: $color-facebook-blue;
        display: flex !important;
        justify-content: center !important;
        border-radius: 5px;
        min-width: 304px;
        margin: 0 4px;
        font-family: Helvetica;
        font-size: $h6-size;

        .cmp-button::before  {
            content: url(./resources/images/facebook.svg);
            filter: brightness(200%);
            display: inline-block;
            width: 15px; 
            height: 15px; 
            margin-left: -30px;
            position: absolute;
            transform: translateY(10%);
            
        }
    }
}

