.centrar-titulo {
    .cmp-title__text{
        text-align: center;
    }
}

.cmp-title-cta-icon-arrow-blue-libertad {
    .cmp-title{
        .cmp-title__text::after  {
                content: url('./resources/images/flecha-azul-circulo.svg');
                display: inline-block;
                width: 15px; 
                height: 15px; 
                margin-left: 5px;
                position: absolute;
                transform: translateY(10%);
                
        }
        .cmp-title__text {

            .cmp-title__link{
                color: $color-title-blue;
                font-family: $font-family-title-bold;
                text-decoration: none;
            }
            .cmp-title__link:hover {
                text-decoration: none;
            }
            color: $color-title-blue;
            font-family: $font-family-title-bold;
        }
    }
}


// CENTRO DE AYUDA //

.cmp-title-centro-ayuda-inactivo {
    .cmp-title{
        margin: 1rem 20px 0 0.75rem;

        .cmp-title__text::after  {
                // content: url('./resources/images/flecha-azul-oscuro-der.svg');
                content: url('https://publish-p107538-e1008189.adobeaemcloud.com/content/dam/web-libertad-servicios-financieros/iconos/flecha-azul-oscuro-der.svg');
                display: inline-block;
                width: 10px; 
                height: 10px; 
                position: absolute;
                left: 31%;
                transform: translateY(30%);
                
        }
        .cmp-title__text {

            
            display: flex;
            justify-content: start;
            color: $color-title-blue;
            font-family: $font-family-title;

            .cmp-title__link{
                color: $color-title-blue;
                font-family: $font-family-title;
                font-size: 1rem;
                text-decoration: none;
                font-weight: 100;
                color: #2d2d2d;
                line-height: 1;
                padding-bottom: 13px;
                margin-left: 20px;

            }
            .cmp-title__link:hover {
                text-decoration: none;
            }
            .cmp-title__link:focus {
                border-color: transparent;
            }
            
            
        }
    }
}

.cmp-title-centro-ayuda-activo {
    margin: 0 0 0 20px;
    background-color: #002c72;
    .cmp-title{
        
        margin: 1rem 20px 0.75rem 20px;

        
        .cmp-title__text {

            
            display: flex;
            justify-content: start;

            .cmp-title__link{
                color: $color-title-blue;
                font-family: $font-family-title;
                font-size: 1rem;
                text-decoration: none;
                font-weight: 500;
                color: #eff6ff;
                padding-bottom: 6px;

            }
            .cmp-title__link:hover {
                text-decoration: none;
            }
            color: $color-title-blue;
            font-family: $font-family-title;
        }
    }
}

div.cmp-title-centro-ayuda-activo ~ div.cmp-title-centro-ayuda-inactivo .cmp-title {
    border-bottom: #7dc0ff 1px solid;
  }


.cmp-title__beneficios-producto {
    .cmp-title__text {
        font-size: $h2-size;
        color: white;
        opacity: 0.41;
        display: inline-block;
    }
}

@media (min-width: $breakpoint-mob-tab) {
    .cmp-title__beneficios-producto {
        .cmp-title {
            margin-left: 30px;
        }
        .cmp-title__text {
            font-size: 133px;
        }
    }
    
    }

@media (max-width: $breakpoint-mob-tab) {
   
    .cmp-title-centro-ayuda-inactivo {
        
    
            .cmp-title__text::after  {
                    // content: url('./resources/images/flecha-azul-oscuro-der.svg');
                    left: 75% !important;
                    
            }
        }
    }


.cmp-title__beneficios-producto-azul {
        .cmp-title__text {
            font-size: $h1-size;
            color: #93afc9;
            display: inline-block;
        }
    }


    .titulo-azul{
.cmp-title__text{
    color: #014593;
}
        
    }
//new style
    .cmp-tittle-colorblue{
        .cmp-title__text{
            color: #014593;
            font-size: 29px;
        }

    }

   