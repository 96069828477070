.white-text{
    .cmp-text{
        p {
            color:white;
        }
    }
    
}

.link-footer{
    .cmp-text{
        p {
            a{
        color: #7dc0ff;
        text-decoration-color: #7dc0ff; 
        
                //color:$color-link;
            }
           
        }
    }
    
}

.link-footer-underline{
    .cmp-text{
        p {
            a{
                color: #7dc0ff;
        text-decoration: underline;
         
                //color:$color-link;
            }
           
        }
    }
}


.text-lib-footer-acordeon-style {
       width: 100% !important;  
       .cmp-text{
     
        text-decoration-line: none;
       }
       

}

