/* CARRUSEL HOME PRDUCTOS */

@media (max-width: $breakpoint-mob-tab) { 
    .cmp-carousel__home-productos{
        .cmp-carousel__actions{
            display: none;
        }
    }
}

@media (min-width: $breakpoint-mob-tab) {

.cmp-carousel__home-productos{
    .cmp-carousel__actions{
        position: absolute;
        justify-content: space-between;
        bottom: 50%;
        right: -7%;;
        width: 114%;
    }

    .cmp-carousel__indicators {
        display: none;
    }
    .cmp-carousel__action {
        background-color: transparent;
    }
    .cmp-carousel__action--previous .cmp-carousel__action-icon:before {        
        /* -webkit-mask: url(http://localhost:7001/content/dam/web-libertad-servicios-financieros/iconos/flecha-azul-izq.svg);
        mask: url(http://localhost:7001/content/dam/web-libertad-servicios-financieros/iconos/flecha-azul-izq.svg);*/

        -webkit-mask: url(./resources/images/flecha-azul-izq.svg);
        mask: url(./resources/images/flecha-azul-izq.svg); 
    
    }

    .cmp-carousel__action--next .cmp-carousel__action-icon:before {
       /*   -webkit-mask: url(http://localhost:7001/content/dam/web-libertad-servicios-financieros/iconos/flecha-azul-der.svg);
        mask: url(http://localhost:7001/content/dam/web-libertad-servicios-financieros/iconos/flecha-azul-der.svg); */

       -webkit-mask: url(./resources/images/flecha-azul-der.svg);
        mask: url(./resources/images/flecha-azul-der.svg);
    }
    
    img{
        max-width: 1439px;
        margin: 0 auto;
    }
    .cmp-carousel__action .cmp-carousel__action-icon:before {
        width: 25px;
        height: 41px;
        background-color: $color-blue-light;
    }
}
}

/* CARRUSEL HERO HOME */

.cmp-carousel_lib_new{

    .cmp-carousel__actions{
        display: none;
    }

    .cmp-carousel .cmp-carousel__content .container .cmp-container {
        background-size: contain !important;
        background-position: right;
    }


}

@media (min-width: $breakpoint-mob-tab) {

.cmp-carousel_lib_new{

    .cmp-image {
        margin-top: 32px;
    }

    .cmp-carousel__indicator--active{
    width: 22px !important;
    height: 10px;
  
    border-radius: 5.1px;
    background-color: #fff;
    }

    .cmp-carousel__indicator{
    width: 10px;
    height: 10px;
  
    border-radius: 5.1px;
    background-color: #fff;
    margin: 0 7px;
    }

    .cmp-carousel__indicators {
    left: 48%;
    bottom: 27%;
    position: absolute;
    }
    .cmp-carousel__actions{
        display: flex;
        position: absolute;
        justify-content: space-between;
        bottom: 50%;
        right: 4%;
        width: 92%;
    }
    .cmp-carousel__action {
        background-color: transparent;
    }
    .cmp-carousel__action--previous .cmp-carousel__action-icon:before {        
        /* -webkit-mask: url(http://localhost:7001/content/dam/web-libertad-servicios-financieros/iconos/flecha-azul-izq.svg);
        mask: url(http://localhost:7001/content/dam/web-libertad-servicios-financieros/iconos/flecha-azul-izq.svg); */

        -webkit-mask: url(./resources/images/flecha-azul-izq.svg);
        mask: url(./resources/images/flecha-azul-izq.svg);
    }

    .cmp-carousel__action--next .cmp-carousel__action-icon:before {
        /* -webkit-mask: url(http://localhost:7001/content/dam/web-libertad-servicios-financieros/iconos/flecha-azul-der.svg);
        mask: url(http://localhost:7001/content/dam/web-libertad-servicios-financieros/iconos/flecha-azul-der.svg); */

        -webkit-mask: url(./resources/images/flecha-azul-der.svg);
        mask: url(./resources/images/flecha-azul-der.svg);
    }
    
    
    .cmp-carousel__action .cmp-carousel__action-icon:before {
        width: 25px;
        height: 41px;
        background-color: $color-blue-light;
    }
}
}
