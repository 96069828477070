.white-title{
    color:white;
}


.libertad-tittle-footer-content{

  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

.link-footer{
    .cmp-text{
        p {
            a{
        color: #7dc0ff !important;
        
                //color:$color-link;
            }
           
        }
    }
    
}

.link-footer-underline{
    .cmp-text{
        p {
            a{
                color: #7dc0ff !important;
                text-decoration: underline;
                text-decoration-color: #7dc0ff !important;
                font-size: $h6-size;
                font-family: $font-family-title;
            }
           
        }
    }
    
}

.h5_style .cmp-title__link {
    font-size: $h6-size;
    font-family: $font-family-title;
    color: #fff;
    text-decoration: none;

}

.link-footer {
    .cmp-title__link {
        color: #7dc0ff !important;
        text-decoration: underline;
        text-decoration-color: #7dc0ff !important;
        font-size: 15px;
        font-family: Libertad Regular;
    }

}