.hero-blog-libertad {
    .aem-Grid {
        height: 564px;
        display: grid;
    }

    .cmp-container {
        background-size: cover !important;
    }
    .cmp-container::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 610px;
        background-image: linear-gradient(98deg, #00459300 31%, #004593ad 44%, #004593 57%);
      }

      .title {
        z-index: 1;
      }
}

.hero-post-libertad {
    .aem-Grid {
        height: 564px;
        display: grid;
    }
    
}


.cmp-container-cuerpo-post {
    .h2_style{
        .cmp-title__text{
        color: $color-title-blue;

        }
    }
}

.cmp-container-autor-post {

    .cmp-separator__horizontal-rule {
        color: $color-blue-light;
        border: solid 2px;
    }

    .cmp-title__text {
        font-family: $font-family-title;
        text-align: right;
    }

    .cmp-text{
        p {
            color: $color-dark-gray;
            text-align: right;
        }
    }

    .cmp-image__image{
        padding: 0 30px;
        max-height: 200px;
        object-fit: contain;
    }
}


@media (min-width: $breakpoint-mob-tab) {

.cmp-container-social-botones {
    .aem-Grid {
        display: flex;
        justify-content: center;
    }
}
}