
//== Defaults

html, body {
  margin: 0;
  color: $color-text;
  font-size: $font-size;
  font-family: $font-family, serif;
  line-height: $font-height;
  background: $color-background;
}

a {
  color: $color-link;
}

p {
  margin-block: 0.75rem;
  line-height: 28px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: 0;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

//== Grid

$max_col: 12;

// Default breakpoint
.aem-Grid {
  @include generate-grid(default, $max_col);
}

// Phone breakpoint
@media (max-width: 768px) {
  .aem-Grid {
    @include generate-grid(phone, $max_col);
  }
}

a:focus {
  border-color: transparent !important;
  border: 0.125em solid #00000000 !important;
}

.cmp-button:focus {
  box-shadow: 0 0 0 0.125em #436fff00 !important;
}