


//// HOME
/// 
/// 
/// 

.cmp-teaser__title-duocolor {
    .cmp-teaser__content {
            .cmp-teaser__description{
            font-size: $h2-size;
            color: $color-dark-gray;
            word-wrap: break-word;
}
            p{
                line-height: 48px;
                b{
                    color: $color-title-blue;
                    font-family: "Libertad Medium";
                }

                i{
                    font-style: normal;
                    font-weight: bold;
                    word-wrap: normal;
                }
            }
        }
    }


.cmp-container__home-pre-footer{
    .cmp-teaser__title-duocolor {
        .cmp-teaser__content {
                .cmp-teaser__description{
                font-size: $h2-size;
                color: #ffffff;
                p{
                    line-height: 48px;
                    b{
                        color: $color-blue-light;
                        font-family: "Libertad Regular";
                        font-size: 68px;
                    }
                }
            }
        }
    }

    .cmp-text {
        p{
            a, a:hover{
                color: $color-title-blue;
                text-decoration: underline $color-title-blue;

            }
        }
    }
}

//// TEMPLATE PRODUCTO
/// 
/// 
/// 

.cmp-teaser__imagen-texto-horizontal {
    .cmp-teaser {
        display: flex;
        min-height: 50px;
        align-items: center;
       
        .cmp-teaser__content {
           
            position: relative;
            top: 0;
            left: 15%;
            transform: none;

            .cmp-teaser__description {
                text-align: left;

                h6 {
                    a{
                        color: $color-bg-gray-acordeon;
                    }
                }

                p {
                    line-height: 1.08;

                    b{
                        font-weight: 600;
                    }
                };
            }
        }

        .cmp-teaser__image {
            flex: 1;
            position: absolute;
            width: 7%;
            margin-left: 4%;

            .cmp-image__image {
                height: auto;

            }
        }
    }
}

@media (min-width: $breakpoint-mob-tab) {
.cmp-teaser__imagen-texto-horizontal {
    .cmp-teaser {
        display: flex;
        min-height: 50px;
        align-items: center;
       
        .cmp-teaser__content {
            
            position: relative;
            top: 0;
            left: 15%;
            transform: none;

            .cmp-teaser__description {
                text-align: left;

                p {
                    line-height: 1.08;
                };
            }
        }

        .cmp-teaser__image {
            flex: 1;
            position: absolute;
            width: 7%;
            margin-left: 4%;

            .cmp-image__image {
                height: auto;

            }
        }
    }
}
}


/// PRODUCTO - IMAGENTE TEXTO COLOR NEGRO
/// 
.cmp-teaser__imagen-texto-horizontal-negro {
    .cmp-teaser {
        display: flex;
        min-height: 50px;
        align-items: center;
       
        .cmp-teaser__content {
            width: 100%;
            flex: 1;
            position: relative;
            top: 0;
            left: 15%;
            transform: none;

            .cmp-teaser__description {
                text-align: left;
                color: #2e2e2e;

                p {
                    line-height: 1.08;

                    b{
                        font-weight: 600;
                    }
                };
            }
        }

        .cmp-teaser__image {
            flex: 1;
            position: absolute;
            width: 7%;
            margin-left: 4%;

            .cmp-image__image {
                height: auto;

            }
        }
    }
}

@media (min-width: $breakpoint-mob-tab) {
.cmp-teaser__imagen-texto-horizontal-negro {
    .cmp-teaser {
        display: flex;
        min-height: 50px;   
        align-items: center;
       
        .cmp-teaser__content {
            width: 100%;
            flex: 1;
            position: relative;
            top: 0;
            left: 15%;
            transform: none;

            .cmp-teaser__description {
                text-align: left;

                p {
                    line-height: 1.08;
                };
            }
        }

        .cmp-teaser__image {
            flex: 1;
            position: absolute;
            width: 7%;
            margin-left: 4%;

            .cmp-image__image {
                height: auto;

            }
        }
    }
}
}


//// HERO PRODUCTO BLANCO
/// 

.cmp-teaser__title-blanco {
    .cmp-teaser__content {
            .cmp-teaser__description{
            font-size: $h2-size;
            color: #ffffff;
            p{
                line-height: 48px;
                b{
                    
                    font-family: "Libertad Medium";
                }

            }
        }
    }
}


.cmp-teaser__beneficios-descripcion {
    .cmp-teaser__content {
            .cmp-teaser__description{
            font-size: 1.5em;
            color: $color-dark-gray;
            text-align: left;
            p{
                line-height: 48px;
                b{
                    color: $color-title-blue;
                    font-family: "Libertad Medium";
                }

                i{
                    font-style: normal;
                    font-weight: bold;
                }
            }

            ul {
                li {
                    b {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.cmp-experiencefragment--poc-footer .cmp-teaser .cmp-teaser__content .cmp-teaser__description p a {
    color: #ffffff;
    text-decoration: none;
}