.cmp-text_negrita-azul{
.cmp-text b {
    color:$color-accent-darker;
}
}

.cmp-text p a {
    word-wrap: break-word;
}

.cmp-text_espacio-lateral{
    .cmp-text h3 {
        padding: 0 15px;
    }
    }

.cmp-text--link-blanco {
    .cmp-text{
        p {
            a {
                color: white;
                text-decoration: underline;
            }

        }
    }
    
}

//// HERO PRODUCTO BLANCO
/// 

.cmp-text__title-blanco {
    .cmp-text{
            font-size: $h2-size;
            color: #ffffff;
            p{
                line-height: 48px;
                b{
                    
                    font-family: "Libertad Medium";
                    font-weight: 400;
                }

            }
        }
    
}

// TEXTO LEGAL //

.cmp-text_legal-prod {
    .cmp-text{
        p {
            font-size: 14px;

            b {
                font-size: 1.3em;
            }
            sup {
                font-size: 14px;
            }

            a {
                color: $color-title-blue;
                text-decoration-color:$color-title-blue;
            }

            a:hover {
                color: $color-title-blue;
                text-decoration-color:$color-title-blue;
            }
        }
    }
}

p.cmp-lib-simulator__disclaimer, 
p.cmp-lib-simulator__youresult-disclaimer, 
p.cmp-lib-originator-final-Cat,
div.cmp-lib-originator-yourOffer-content p,
p.cmp-lib-originator-yourOffer-rightsReserved
{
    font-size: 14px;

    b, strong, span {
        font-size: 1.3em;
    }
    sup {
        font-size: 14px;
    }

    a {
        color: $color-title-blue;
        text-decoration-color:$color-title-blue;
    }

    a:hover {
        color: $color-title-blue;
        text-decoration-color:$color-title-blue;
    }
}

.cmp-lib-simulator__ideal-product-description,
label.cmp-lib-simulator__yourdata-checkbox-label {
    
    a {
        color: $color-title-blue;
        text-decoration-color:$color-title-blue;
    }

    a:hover {
        color: $color-title-blue;
        text-decoration-color:$color-title-blue;
    }

}


strong.strong-small {
    font-size: 14px !important;
}

//cambios 
.cmp-text_negrita-azul-pdigitales {

    .cmp-text b { 

        color: #014593;
}
}


.link-azul-lib{
    .cmp-text p{
        a{
            color: #014593;
            text-decoration: underline;
        }
    } 

}
