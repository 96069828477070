.cmp-carousel {
  margin: 0;
}

.cmp-carousel__content {
  position: relative;
}

.cmp-carousel__item {}

.cmp-carousel__actions {
  position: absolute;
  right: 0.5em;
  bottom: 1.75em;
  display: flex;
  flex-direction: row;
  float: right;
}

.cmp-carousel__action {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0.125em;
  background-color: $color-shade-1;
  border-width: 0;
  border-radius: 0.25em;
  box-shadow: none;

  & .cmp-carousel__action-icon {
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: inline-block;
      width: 24px;
      height: 24px;
      background-color: $color-shade-3;
      transform: translate(-50%, -50%);
      content: '';
      mask-size: cover;
    }
  }

  &:focus {
    outline: none;
  }
}

.cmp-carousel__action--disabled {}

.cmp-carousel__action--previous {
  & .cmp-carousel__action-icon {
    &:before {
      mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00ODUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDg1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNLjI5MywxMy4wMjFhMSwxLDAsMCwxLDAtMS40MTVsNC45NS00Ljk1TC4yOTMsMS43MDdBMSwxLDAsMCwxLDEuNzA3LjI5M0w3LjM2NCw1Ljk1YTEsMSwwLDAsMSwwLDEuNDE0TDEuNzA3LDEzLjAyMWExLDEsMCwwLDEtMS40MTUsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUwMSA5OC42NTcpIHJvdGF0ZSgxODApIi8+PC9nPjwvc3ZnPg==');
    }
  }
}

.cmp-carousel__action--next {
  & .cmp-carousel__action-icon {
    &:before {
      mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00NDUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDQ1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNMzAuNzA3LTMuNTY1YTEsMSwwLDAsMSwwLTEuNDE0bDQuOTQ5LTQuOTUtNC45NDktNC45NWExLDEsMCwwLDEsMC0xLjQxNCwxLDEsMCwwLDEsMS40MTQsMGw1LjY1Nyw1LjY1NmExLDEsMCwwLDEsLjI5My43MDcsMSwxLDAsMCwxLS4yOTMuNzA3TDMyLjEyMS0zLjU2NWExLDEsMCwwLDEtLjcwNy4yOTNBMSwxLDAsMCwxLDMwLjcwNy0zLjU2NVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQyMi45MjkgMTAxLjkyOSkiLz48L2c+PC9zdmc+');
    }
  }
}

.cmp-carousel__action--pause {
  display: none;
}

.cmp-carousel__action--play {
  display: none;
}

.cmp-carousel__action-icon {}

.cmp-carousel__action-text {
  display: none;
}

.cmp-carousel__indicators {
  height: 20px;
}

.cmp-carousel__indicator {
  width: 2.5rem;
  height: 0.125rem;
  margin: auto 0.125rem 0 0.125rem;
  background-color: $color-shade-1;
  border-radius: 0.125rem;
}

.cmp-carousel__indicator--active {
  background-color: $color-shade-4;
}


//acordeon menu flotante

.acordion-lib-menuflotante {
  .cmp-carousel {


    .cmp-carousel__actions {

      font-size: 24px;
      cursor: pointer;
      margin: 0px 15px;
      display: flex;
      justify-content: space-between;
    }

    & .cmp-carousel__action--previous {
      margin-bottom: 180px;
      margin-right: 178px;
      background: none;
    }

    & .cmp-carousel__action--next {
      background: none;
      margin-right: 100px;
      left: 180px;

    }


    @media (max-width: 768px) {
      .cmp-carousel__action--previous {
        background: none;
        margin-bottom: 40px;
        margin-right: 180px;
      }

      .cmp-carousel__action--next {
        background: none;
        margin-right: 160px;
        left: 110px;
      }
    }


    @media (max-width: 480px) {
      .cmp-carousel__action--previous {
        margin-right: 50px;
        margin-bottom: 40px;
        background: none;
      }

      .cmp-carousel__action--next {
        background: none;
        left: 105px;
        margin-right: 100px;
      }
    }

    @media (max-width: 375px) {
      .cmp-carousel__action--previous {
        background: none;
        margin-bottom: 40px;
        margin-right: 30px;
      }

      .cmp-carousel__action--next {
        background: none;
        left: 105px;
        margin-right: 100px;
      }

    }



  }



  .cmp-carousel__indicators {
    display: none;
  }

}



.carousel-lib-tarjeta-mobile-menu-flotante{

  .cmp-carousel__content{
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }
}


.carrusel-pdigitales {

  @media (max-width: 375px)   {
   
    .cmp-carousel__indicators {

      left: 40%;
      
      position: absolute;
      

    }

    .cmp-carousel__indicator--active{
      width: 22px !important;
      height: 10px;
    
      border-radius: 5.1px;
      background-color: #2954ff !important;
      }


      .cmp-carousel__indicator{
        
        background-color: #bfbfbf;
        border-radius: 5.1px;
        width: 10px;
        height: 10px;
        
        }
    
  }

  @media (max-width: 425px)   {
   
    //indicadores barra completa
    .cmp-carousel__indicators {

      left: 42%;
      
      position: absolute;
      

    }
//indicador activo
    .cmp-carousel__indicator--active{
      width: 22px !important;
      height: 10px;
    
      border-radius: 5.1px;
      background-color: #014593 !important;
      }

//indicador de los lados
      .cmp-carousel__indicator{
        
        background-color: #bfbfbf;
        border-radius: 5.1px;
        width: 10px;
        height: 10px;
        }
    
  }




  @media (max-width: 768px)   {
   
    //indicadores barra completa
    .cmp-carousel__indicators {

      left: 45%;
      
      position: absolute;
      

    }
//indicador activo
    .cmp-carousel__indicator--active{
      width: 22px !important;
      height: 10px;
    
      border-radius: 5.1px;
      background-color: #014593 !important;
      }

//indicador de los lados
      .cmp-carousel__indicator{
        
        background-color: #bfbfbf;
        border-radius: 5.1px;
        width: 10px;
        height: 10px;
        }
    
  }


}

@media (min-width: 1024px) {
  .hide-indicators-desktop .cmp-carousel__indicators {
      visibility: hidden;
  }
}