/* @media (min-width: $breakpoint-wide) {
    .aem-Grid {
        max-width: 1920px;
        margin: 0 auto;
    }
    
} */

button {
    cursor: pointer;
}

.cmp-lib-header {
    position: absolute;
}


/* CARRUSEL TRIPLE */

.lib-carrusel-triple__buttons-section {
    z-index: 10 !important;
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: row-reverse !important;
    top: 217px !important;
    width: 110% !important;
    right: 5% !important;
}

.lib-cmp__carrusel-triple-button-right, .lib-cmp__carrusel-triple-button-left {
    position: relative !important;
    top: 0 !important;
}

.lib-cmp__carrusel-triple__contenedor-img-container {
    box-shadow: 0 2px 13px #00000026 !important;
    width: 263px !important;
    height: 303px !important;
}

.lib-cmp__carrusel-triple__content__image {
    width: 100% !important;
}

@media (max-width: $breakpoint-mob-tab) {
    .lib-cmp__carrusel-triple-button-right, .lib-cmp__carrusel-triple-button-left {
        left: 0% !important;
    }

}

p.lib-cmp__carrusel-triple__content__title-tab {
    font-family: Libertad Medium;
    font-size: 1.1875rem;
    font-weight: normal;
    margin-top: 15px !important;
}

.lib-cmp__carrusel-triple__content__description {
    margin-bottom: 15px;
}

.lib-cmp__carrusel-triple__content__description p {
    margin-block: 0.5rem !important;
    line-height: 1.13 !important;
    font-size: 1em !important;
}

.lib-cmp__carrusel-triple__content__description p a {
    color: #2954ff;
}

.lib-cmp__carrusel-triple__content__image{
    border-radius: 10px;
}

/* HEADER */
@media (min-width: 1200px) and (max-width: 1439px) {
.cmp-lib-header__container {
    margin: 0 5% !important;
    max-width: 1150px !important;
}
}

.cmp-lib-header__submenu-submenu-item {
    font-size: $font-size;
    margin-bottom: 12px;
    width: 100%;
}

.cmp-lib-header__menu-item {

    margin-right: 55px !important;

    a{
        font-size: $font-size !important;
    }
}

.cmp-lib-header__buttons, .cmp-lib-header__submenu-item {

    a{
        font-size: $font-size !important;
    }
}

.cmp-lib-header__submenu-submenu-item {
    margin-bottom: 10px !important;
    font-size: $font-size !important;
}