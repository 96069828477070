

.cmp-container__home-float-bar {
.cmp-container {
  .contenedor-flex {
    .cmp-text {
      p a {
        color: black !important;
        text-decoration: none !important;
      }

    }
  }
}
}
.homepage-content-container {
margin-top: 0;
}



.cmp-container__home-cards {

&> .cmp-container{

  &> .aem-Grid {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
}

.elemento-flex {
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  min-height: 392px;
  min-width: 316px;
  max-width: 350px;
  .cmp-container{    
    border-radius: 8px;

   

    .cmp-title{
      margin: 20px 33px 0 33px;
      .cmp-title__text{
        position: relative;
      }        
    }

    .cmp-text{
      margin: 30px 33px 0 33px;
      p{
        margin-block: 0.5rem;
        line-height: 1.13;
      }
    }

    .cmp-image {
      margin-top: 0;
    }
  }
  
}
}

.cmp-carousel__home-cta-app {
  padding: 10px 0 10px;
  background-color: $color-title-light-blue-bg;
.cmp-container{
  .cmp-text{
    margin: 0;
    p{
      margin-block: 0.5rem;
    }
  }
}
  .call-to-action{

    a {
      color: transparent;
    }
    .cmp-button {
      background-color: $color-title-blue;
      border-color: $color-title-blue;
      border-radius: 0.5em;
      display: block;
      margin: 0 0 0 2em;
    }
}
}

.cmp-container__home-inf-pods {
    .cmp-title__text {
      font-family: $font-family-title-bold;
      color: $color-dark-gray;
      line-height: 1.08;
    }
} 


@media (max-width: $breakpoint-mob-tab) {

.cmp-container__home-cards {

  &> .cmp-container{
  
    &> .aem-Grid {
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
      gap: 20px 0;
    }
  }
}
}

@media (min-width: $breakpoint-mob-tab) {
  .cmp-container__home-float-bar {
  position: relative;
  top: -150px;
  box-shadow: 0 14px 36px 0 rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  &> .cmp-container{
    border-radius: 8px;
    padding: 0 54px;
  }

  .elemento-flex {
    flex: 1;
    img {
      max-width: 52px;
      margin: 0 auto;
    }
  }
}
}