.cmp-list-libertad-posts-relacionados {
  .cmp-list {
    display: block;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px; /* Espacio entre las columnas y filas, ajusta según tus necesidades */
    list-style-type: none; /* Oculta el símbolo de viñeta */
  }
}

/*     .cmp-list-libertad-malla-posts {
        .cmp-list {
         display: block;
         grid-template-columns: repeat(3, 1fr);
         gap: 10px; 
         list-style-type: none; 
        }
} */

.cmp-list-libertad-malla-posts {
  .cmp-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px; /* Espacio entre las columnas y filas, ajusta según tus necesidades */
    list-style-type: none; /* Oculta el símbolo de viñeta */
    max-width: 1050px;
    margin: 0 auto;

    li {
      width: 100%;
      max-width: 290px;
      .cmp-teaser__content {
        z-index: 1;
        top: 68%;
        width: 80%;
        .cmp-teaser__title {
          font-size: 1.5rem;
          font-family: $font-family-title;
          line-height: normal;
        }
        .cmp-teaser__description {
          line-height: normal;
          font-size: 0.8rem;
        }
      }
      .cmp-image::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 30%,
          rgba(0, 69, 147, 0.59) 71%,
          #004593 101%,
          #000 101%
        );
        border-radius: 8px;
      }
      .cmp-image__image {
        object-fit: contain;
        height: auto;
      }
    }
  }
}

.cmp-list-libertad-posts-relacionados {
  .cmp-list {
    display: flex;
    justify-content: center;
    gap: 10px; /* Espacio entre las columnas y filas, ajusta según tus necesidades */
    list-style-type: none; /* Oculta el símbolo de viñeta */

    li {
      width: auto;
      height: auto;
      padding: 8px;
      margin: 16px 0;
      border-radius: 5px;
      background-color: #fff;

      a {
        color: $color-dark-gray;
        font-size: $h6-size;
        text-decoration: none;

        .cmp-list__item-title {
          display: block;
          overflow: hidden;
          margin: 0 auto;
          font-weight: 600;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-mob-tab) {
  .cmp-list-libertad-posts-relacionados {
    .cmp-list {
      display: initial;
      list-style-type: none; /* Oculta el símbolo de viñeta */
    }
  }

  .cmp-list-libertad-malla-posts {
    .cmp-list {
      display: flex;
      list-style-type: none; /* Oculta el símbolo de viñeta */
    }
  }
}
