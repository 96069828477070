.h4_style {
  color: #004593;

  .cmp-title__text{
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 40px;
  }

  .cmp-text {
    h1{
      a{
        line-height: 40px;
        color: #004593;
      }
    }
  }
  
}

@media (min-width: $breakpoint-mob-tab) {
  
    .contenedor-flex {
      & > .cmp-container {
          & > .aem-Grid{
      display: flex;
      justify-content: space-between;
    }
      }
    .elemento-flex {
      flex: 1;
    }
  }
}

.xf-content-height {
  margin: 0 !important;
}




.cmp-container--centro-ayuda-cont {
  padding-left: 50px;

  .container {
    padding: 0 5% 0 0;
  }

    .title.h3_style{
      border-bottom: #7dc0ff 1.4px solid;

      h3 {
        font-size: 1.8rem;
        font-weight: 600;
      }
  }

  .h4_style {
    color: #004593;

    .cmp-title__text{
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 40px;
    }
    
  }

  .text {
    p {
      a {
        color: #0053a1;
        text-decoration: underline;
      }
    }
  }
}


.cmp-container--centro-ayuda-bar {

  .h2_style {

    .cmp-title__text{
      font-weight: 600;
      font-size: 3.125rem;
    }
    
  }
  .cmp-text p a, .cmp-text p b {
    color: #004593;
    text-decoration: none;
  }

  .cmp-text_negrita-azul, .cmp-text_espacio-lateral {
    .cmp-text {
      color: #595a5b;
    }
  }

  .cmp-text_espacio-lateral {
    .cmp-text {
      font-size: 1.5rem;
    }
  }
}


@media (max-width: $breakpoint-mob-tab) {

  .cmp-container--centro-ayuda-cont {
    padding-left: 0;

}

.cmp-container-no-bg-img-mob {
  .cmp-container {
    background-image: none !important;
  }


  div.cmp-text__title-blanco {
    .cmp-text p {
      max-width: 95%;
      margin: 0 auto;
    }
  }
}
}

.cmp-container--centro-ayuda-nav {
  overflow: hidden;
  .aem-Grid {
    padding-top: 32px;    
    padding-bottom: 20px;
  }
}